import * as React from "react"
import TreatmentsInner from "./treatmentsInner"
import { returnLink } from "../../../helpers/returnLink"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { EDTextComponents } from "../../TextComponents/erectile-dysfunction"
import HeaderImg from "../../../images/erectile-dysfunction/edHeader.webp"
import About1 from "../../../images/erectile-dysfunction/edImg1.webp"
import About2 from "../../../images/erectile-dysfunction/edImg2.webp"
import { Link } from "gatsby"

const faqLdJSON = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Quels sont les symptômes de la dysfonction érectile ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Les symptômes de la dysfonction érectile peuvent inclure des difficultés à obtenir ou maintenir une érection, une diminution du désir sexuel et des problèmes pour atteindre une performance sexuelle satisfaisante. Si vous éprouvez l'un de ces symptômes, consultez un professionnel de la santé pour un diagnostic approprié.",
      },
    },
    {
      "@type": "Question",
      name: "Que devrais-je faire si j'ai des problèmes d'éjaculation précoce (PE) ou de dysfonction érectile (ED) ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Si vous soupçonnez avoir des troubles sexuels, commencez par utiliser un outil en ligne d'évaluation des symptômes. Apportez ces résultats à votre rendez-vous médical afin d'identifier une cause. Une fois la cause identifiée, plusieurs options de traitement seront disponibles.",
      },
    },
    {
      "@type": "Question",
      name: "Quels sont les symptômes de l'éjaculation précoce ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Les symptômes de la dysfonction érectile peuvent inclure des difficultés à obtenir ou à maintenir une érection, une diminution du désir sexuel et des défis pour atteindre une performance sexuelle satisfaisante. Si vous éprouvez l'un de ces symptômes, consultez un professionnel de la santé pour un diagnostic approprié.",
      },
    },
    {
      "@type": "Question",
      name: "Comment Clinique de Santé Masculine se distinguent-elles ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Nous sommes la principale clinique nationale au Canada offrant des solutions de traitement entièrement personnalisées et gérées pour les hommes souffrant d'éjaculation précoce et de dysfonction érectile. Chaque homme est différent. Notre traitement sur mesure varie pour chaque personne afin de garantir la meilleure chance de guérison avec des effets secondaires minimes. Nos gestionnaires de performance des patients (PPM) évaluent vos résultats mensuellement pour s'assurer que vous atteignez le plus haut niveau de performance sexuelle. Pour raviver votre vie amoureuse, planifiez une consultation GRATUITE avec Clinique de Santé Masculine Canada.",
      },
    },
    {
      "@type": "Question",
      name: "Comment Clinique de Santé Masculine peut-elle aider?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Clinique de Santé Masculine est une clinique de télésoins en ligne qui dessert des milliers d'hommes partout au Canada. Notre équipe de médecins canadiens agréés est spécialisée dans la santé des hommes, avec des décennies d'expérience dans le domaine. Nous proposons des plans de traitement personnalisés aux patients luttant contre l'éjaculation précoce. Nous encourageons les hommes à reprendre le contrôle de leur vie sexuelle et à se sentir plus confiants à l'intérieur et à l'extérieur de la chambre à coucher. Pour en savoir plus sur les solutions personnalisées de Clinique de Santé Masculine, appelez-nous dès aujourd'hui au +1 (800) 422 8149.",
      },
    },
    {
      "@type": "Question",
      name: "Comment puis-je traiter ma préoccupation médicale?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Une fois que vous avez reçu votre diagnostic, notre équipe de pharmacie expédiera vos ordonnances directement à votre porte dans un emballage discret. Informez-vous davantage sur la manière dont Clinique de Santé Masculine peut vous aider à retrouver votre confiance et à recommencer à apprécier la vie sexuelle.",
      },
    },
  ],
}

const videoLdJSON = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: "Rencontrez le Dr Fred Edwards.",
  description:
    "Plongez dans l'univers de Clinique de Santé Masculine, une clinique de télésanté établie avec plus de 10 ans d'expérience, une équipe de plus de 150 membres et un historique de traitement de plus de 15 000 hommes dans le monde entier.",
  thumbnailUrl: [
    "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1390952176-93a902607d3811d11a66de5828ce204fa39a69119d3abbe36d8cc602a2eb6193-d_1280x720&src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png",
  ],
  uploadDate: "2022-08-24T08:00:00+08:00",
  contentUrl:
    "https://menshealthclinic.com/au/treatments/premature-ejaculation",
  embedUrl:
    "https://player.vimeo.com/video/635035287?h=1479562035&title=0&byline=0&portrait=0",
  potentialAction: {
    "@type": "SeekToAction",
    target:
      "https://menshealthclinic.com/au/treatments/premature-ejaculation={seek_to_second_number}",
    "startOffset-input": "required name=seek_to_second_number",
  },
}

const ErectileDysfunction = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Traitement de la dysfonction érectile",
    description:
      "Clinique de Santé Masculine aide les hommes avec le traitement de la dysfonction érectile depuis plus d'une décennie en fournissant des plans de traitement personnalisés adaptés aux besoins individuels de nos patients. Clinique de Santé Masculine offre un environnement sûr et favorable pour les hommes cherchant de l'aide.",
    url: `https://menshealthclinic.com/${prefix}/treatments/erectile-dysfunction`,
  }
  // WEBPAGE SCHEMA END //

  const aboutTreatmentImg = React.useMemo(() => {
    return About1
  }, [prefix])

  const EDQuizLink = React.useMemo(() => {
    return `https://app.menshealthclinic.com/assessment/quiz?category_id=1&source=Website-Quiz&formCompleted=Embedded-Quiz&prefix=${prefix}&formUrl=?edwebquiz`
  }, [prefix])

  let EDSeoTitle =
    "Traitement de la dysfonction érectile au Canada | Solutions pour la DE | MHC"
  let EDSeoDescription =
    "Obtenir un traitement pour la dysfonction érectile au Canada est facile et pratique avec Clinique de Santé Masculine. Planifiez une consultation en ligne pour le traitement de la DE dès aujourd'hui."
  let EDTitle = "Traitement de la dysfonction érectile au Canada"

  const stories = [
    {
      user: "Brian J.",
      title: "Dysfonction érectile",
      story: `J'ai trouvé Clinique de Santé Masculine très utile et soutenante pour restaurer ma confiance en moi et ma relation avec ma partenaire. Clinique de Santé Masculine a été très accessible et m'a contacté régulièrement pour évaluer mes progrès.`,
    },
    {
      user: "Christian M.",
      title: "Éjaculation précoce",
      story: `Depuis que je suis sur le programme de Clinique de Santé Masculine pour aider avec l'éjaculation précoce, cela a été une excellente expérience. Ils sont très utiles et savent de quoi ils parlent. Je recommande vivement ce programme à tout homme cherchant de l'aide !`,
    },
    {
      user: "A Sal.",
      title: "",
      story: `Depuis que j'ai rejoint Clinique de Santé Masculine, ma vie s'est nettement améliorée. Ma confiance en moi, ma relation, ma santé générale et mon bien-être se sont considérablement améliorés. Les conseils et le soutien à Clinique de Santé Masculine ont été exceptionnels. Merci pour tout, j'attends avec impatience de travailler avec vous à l'avenir. Je recommanderais à quiconque de parler aux gars de Clinique de Santé Masculine, vous ne serez pas déçu.`,
    },
  ]

  const questions = [
    {
      question: "Qu'est-ce que la dysfonction érectile (DE) ?",
      answer: {
        Component: () => (
          <span>
            ED est l'acronyme de dysfonction érectile. La dysfonction érectile
            est l'incapacité à obtenir ou à maintenir une érection pendant les
            rapports sexuels.
            <a
              className="text-3FB1FC"
              href="https://www.healthing.ca/wellness/men/erectile-dysfunction-causes/#:~:text=It's%20estimated%20that%20nearly%2050,allows%20for%20satisfactory%20sexual%20activity"
            >
              {"(1)"}{" "}
            </a>
            Dans de nombreux cas, la dysfonction érectile peut entraîner de
            l'anxiété, une faible confiance en soi, une estime de soi basse et
            de la frustration.
          </span>
        ),
      },
    },
    {
      question:
        "Devrais-je consulter un médecin au sujet de la dysfonction érectile (DE)?",
      answer: {
        Component: () => (
          <span>
            La recherche montre que ne pas traiter la dysfonction érectile peut
            entraîner des complications supplémentaires et l'impuissance.
            <a
              className="text-3FB1FC"
              href="https://www.mayoclinic.org/diseases-conditions/erectile-dysfunction/symptoms-causes/syc-20355776"
            >
              (1)
            </a>{" "}
            Si vous avez constamment des difficultés à obtenir ou à maintenir
            une érection pendant une période de trois mois ou plus, vous
            pourriez avoir une dysfonction érectile. Cela signifie que les
            hommes qui rencontrent des problèmes de dysfonction érectile
            devraient consulter immédiatement un professionnel de la santé.
          </span>
        ),
      },
    },
    {
      question: "Comment puis-je obtenir mon traitement?",
      answer: {
        Component: () => (
          <span>
            Une fois que vous avez reçu votre diagnostic, notre équipe de
            pharmacie expédiera vos prescriptions directement à votre porte dans
            un emballage discret. En savoir plus sur la façon dont Clinique de
            Santé Masculine peut vous aider à retrouver votre confiance et à
            recommencer à profiter du plaisir sexuel.{`\n`}
            Appelez-nous au{" "}
            <a
              className="text-3FB1FC"
              href={`tel:${TextCopies.countryPhone(prefix)}`}
            >
              {TextCopies.countryPhone(prefix)}
            </a>{" "}
            ou visitez notre site web pour réserver une consultation
            téléphonique dès aujourd'hui.{`\n`}
          </span>
        ),
      },
    },
    {
      question:
        "Quel est le coût du traitement de la dysfonction érectile (DE)?",
      answer: {
        Component: () => (
          <span>
            Le traitement de la dysfonction érectile (DE) est généralement
            abordable. Vous pouvez en savoir plus sur l'accès à des options de
            traitement abordables pour la dysfonction érectile avec Clinique de
            Santé Masculine. Tous nos plans de traitement sont entièrement
            personnalisés en fonction de vos besoins, car ils sont basés sur la
            gravité de votre état et les causes présumées. Pour obtenir des
            informations sur les prix,
            <Link className="text-3FB1FC" to={`/${prefix}/treatment-cost`}>
              cliquez ici
            </Link>
          </span>
        ),
      },
    },
    {
      question:
        "Comment commencer le traitement de la dysfonction érectile (DE) ?",
      answer: {
        Component: () => (
          <span>
            Pour commencer le traitement de la dysfonction érectile (DE),
            planifiez simplement une consultation téléphonique gratuite
            <a className="text-3FB1FC" href={TextCopies.acuityLink(prefix)}>
              {" "}
              ici
            </a>
            , remplissez
            <a
              className="text-3FB1FC"
              href={returnLink(
                "Website-Quiz",
                "Supplementary-Quiz",
                "edfaqquiz",
                false,
                prefix
              )}
            >
              {" "}
              notre{" "}
            </a>
            évaluation rapide de deux minutes ou appelez-nous au
          </span>
        ),
      },
      telLink: TextCopies.countryPhone(prefix),
    },
    {
      question:
        "Quels traitements sont disponibles pour la dysfonction érectile (DE) ?",
      answer: `Clinique de Santé Masculine propose une variété de plans de traitement sur mesure adaptés aux besoins individuels. Aucun homme n'est identique ; plutôt que de suivre une approche générique des soins de santé masculins, nous croyons que des solutions personnalisées offrent une opportunité de succès plus élevé du traitement, car elles se concentrent entièrement sur votre parcours.\n\nLes traitements comprennent une formulation personnalisée basée sur votre historique médical et vos habitudes de vie. De plus, des exercices, des conseils diététiques de notre nutritionniste clinique et des auto-injections peuvent être prescrits en fonction de vos besoins. Notre médecin hautement expérimenté vous fournira des instructions sur la manière de suivre le traitement. Un accompagnement privé est disponible pour aider les patients à faire face aux défis psychologiques, et un soutien mensuel est également disponible avec l'aide de nos gestionnaires de performance dédiés, dont l'objectif est de garantir que les patients sont satisfaits du traitement.`,
    },
    {
      question: "Comment est diagnostiquée la dysfonction érectile (DE) ?",
      answer: `Pour obtenir un diagnostic de la dysfonction érectile (DE), vous devrez parler à un professionnel de la santé agréé, comme ceux que nous avons à Clinique de Santé Masculine. Au cours de cette consultation discrète, on pourrait vous demander de partager des informations sur votre historique médical, vos habitudes de vie, les symptômes de la dysfonction érectile que vous ressentez, ainsi que votre historique sexuel.\n\nNos médecins évalueront ensuite vos informations, et si vous êtes un candidat approprié pour notre traitement, ils élaboreront un plan de traitement personnalisé adapté à vos besoins.`,
    },
    {
      question: "Quelles sont les causes de la dysfonction érectile (DE) ?",
      answer: {
        Component: () => (
          <span>
            Il existe de nombreuses causes différentes de la dysfonction
            érectile (DE), mais elle se produit souvent lorsque les nerfs dans
            le pénis sont restreints ou qu'il y a un afflux sanguin insuffisant.{" "}
            <a
              className="text-3FB1FC"
              href="https://www.urologyhealth.org/urology-a-z/e/erectile-dysfunction-(ed)"
            >
              (1)
            </a>
            .{`\n\n`}
            La dysfonction érectile peut être catégorisée en deux catégories :
            physiologique et psychologique. Les causes physiques de la DE
            comprennent, sans s'y limiter, le diabète, l'âge, l'hypertension
            artérielle, l'obésité, le taux élevé de cholestérol, la DE induite
            par des médicaments. Les causes psychologiques de la DE peuvent
            inclure des troubles de la santé mentale, le stress, l'anxiété, la
            rupture de relations.{" "}
          </span>
        ),
      },
    },
  ]

  return (
    <TreatmentsInner
      prefix={prefix}
      schema={schema}
      mainTitle="Clinique de Santé Masculine"
      title={EDTitle}
      seoTitle={EDSeoTitle}
      seoDescription={EDSeoDescription}
      slug="erectile-dysfunction"
      faqSeoLdJSON={faqLdJSON}
      videoSeoLdJSON={videoLdJSON}
      text="Performez mieux et retrouvez la confiance que vous méritez."
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "edmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "edbottomquiz",
        false,
        prefix
      )}
      headerBannerCTAlink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "edbannerquiz",
        false,
        prefix
      )}
      headerBannerImg={HeaderImg}
      headerBannerImgAlt="Solutions pour la dysfonction érectile"
      quizTitle={EDTextComponents.returnQuizTitle(prefix)}
      quizText={EDTextComponents.returnQuizText(prefix)}
      aboutTreatmentTitle={`Traitement de la dysfonction érectile à Clinique de Santé Masculine.`}
      aboutTreatmentText={EDTextComponents.aboutTreatmentText(prefix)}
      aboutTreatmentImg={aboutTreatmentImg}
      aboutTreatmentImgAlt="
      Couple heureux se tenant la main en courant sur la plage - Clinique de Santé Masculine."
      aboutTreatmentTitleSecond="Qu'est-ce que la dysfonction érectile ?"
      aboutTreatmentTextSecond={EDTextComponents.aboutTreatmentTextSecond(
        prefix
      )}
      aboutTreatmentTitleThird={`Quelles sont les causes de la dysfonction érectile ?\n`}
      aboutTreatmentTextThird={EDTextComponents.aboutTreatmentTextThird(prefix)}
      aboutTreatmentImgSecond={About2}
      aboutTreatmentImgSecondAlt="Portrait rapproché d'un couple heureux après un rapport sexuel durable - Clinique de Santé Masculine"
      secondDashedTitle="Dysfonction érectile (DE)"
      firstDashedTitle="À propos de notre traitement"
      processQuizLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edprocessquiz",
        false,
        prefix
      )}
      faqsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edfaqquiz",
        false,
        prefix
      )}
      reviewsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edreviewsquiz",
        false,
        prefix
      )}
      aboutLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edsymptoms",
        false,
        prefix
      )}
      assessmentQuizBtnLink={`/${prefix}/treatment-cost`}
      iframeLink={EDQuizLink}
      iframeSizeClass="edIframeSize"
      faqTitleText="Foire aux questions"
      questions={questions}
      headerMarksText={[
        `Médecins autorisés au Canada`,
        "Traitements pratiques et sur mesure.",
        "Expédition discrète.",
        "Soins dévoués continus aux patients.",
      ]}
      embeddedQuizHeaderText={
        <>
          <strong className="text-16 font-bold">60 000 HOMMES</strong> ont été
          évalués avec succès grâce à notre quiz de vérification des symptômes
          GRATUIT de 2 minutes.
        </>
      }
      experts={TextCopies.experts(prefix)}
      expertsTitle="Offrant un haut degré de qualité, d'excellence et de soin."
      patientsStories={stories}
      blueDashedText="Rencontrez le Dr. Fred Edwards"
    />
  )
}
export default ErectileDysfunction
